<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <a-card>
                <a-row type="flex" :gutter="[8, 32]">
                    <a-col>
                        <a-tree-select
                            style="width: 200px"
                            tree-default-expand-all
                            multiple
                            allowClear
                            :placeholder="l('Company')"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="companyTreeList"
                            v-model="companyId"
                            @change="refresh"
                        />
                    </a-col>
                    <a-col>
                        <a-button type="primary" @click="Export()" v-if="isGranted('sl_baseinfo_excel')">
                            <a-icon type="download" />
                            <span>导出</span>
                        </a-button>
                    </a-col>
                </a-row>
                <a-row :gutter="[8, 32]">
                    <a-col>
                        <div class="emp-provice" :style="{ 'max-height': scroll_y + 'px' }">
                            <table class=" emp-table" v-if="isshow">
                                <thead>
                                    <tr>
                                        <th rowspan="2" style="border-top: 2px solid #A9A8AC">集团</th>
                                        <th rowspan="2">公司</th>
                                        <th rowspan="2">部门</th>
                                        <th
                                            colspan="2"
                                            v-for="item in tableData[0].provinceTops"
                                            :key="item.province"
                                            style="border-bottom: 2px solid #A9A8AC"
                                        >
                                            {{ item.province }}
                                        </th>
                                    </tr>
                                    <tr class="prop">
                                        <th>人数</th>
                                        <th>比例</th>
                                        <th>人数</th>
                                        <th>比例</th>
                                        <th>人数</th>
                                        <th>比例</th>
                                    </tr>
                                </thead>
                            </table>
                            <tbody class="tbody">
                                <!--                            <tr>-->
                                <!--                                <th>{{item.parentCompany}}</th>-->
                                <!--                                <th>{{item.company}}</th>-->
                                <!--                                <th>{{item.department}}</th>-->
                                <!--                                <th v-for="(p) in item.provinceTops" :key="index">1</th>-->
                                <!--                            </tr>-->
                            </tbody>
                        </div>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col>
                        <a-pagination
                            class="pagination"
                            :total="totalItems"
                            v-model="pageNumber"
                            showSizeChanger
                            showQuickJumper
                            :showTotal="showTotalFun"
                            @change="onChangePage"
                            @showSizeChange="showSizeChange"
                        />
                    </a-col>
                </a-row>
            </a-card>
        </a-spin>
    </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import { AppComponentBase } from '../../../../shared/component-base';
import { CompanyServiceProxy, CustomizeReportServiceServiceProxy } from '../../../../shared/service-proxies';
import { fileDownloadService } from '../../../../shared/utils';
import * as $ from 'jquery';

export default {
    name: 'emp-province-report',
    mixins: [AppComponentBase],
    data() {
        return {
            zh_CN,
            companyTreeList: [],
            companyId: undefined,
            //表格上方间隔
            defaultTop: 60,
            tableData: [],
            totalItems: 0, //总数
            // 当前页码
            pageNumber: 1,
            // 共多少页
            totalPages: 1,
            // 条数显示范围
            pagerange: [1, 1],
            // 显示条数
            pageSizeOptions: ['10', '20', '30', '40'],
            request: { maxResultCount: 10, skipCount: 0, sorting: '' },
            isshow: false,
        };
    },
    created() {
        this._companyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
        this._customizeReportServiceServiceProxy = new CustomizeReportServiceServiceProxy(this.$apiUrl, this.$api);
        this._fileDownloadService = fileDownloadService;
    },
    mounted() {
        this.companyTreeInit();
        this.refresh();
    },
    methods: {
        refresh() {
            this.pageNumber = 1;
            this.request.skipCount = 0;
            this.getData();
        },
        getData() {
            this.isshow = false;
            this.loading = true;
            this._customizeReportServiceServiceProxy
                .getEmpProvinceReport(
                    this.companyId,
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.tableData = res.items;
                    this.isshow = true;
                    if (res.items.length > 0) {
                        setTimeout(() => {
                            $('.prop').html('');
                            $('.tbody').html('');
                            this.tableData[0].provinceTops.map((item) => {
                                $('.prop').append('<th>人数</th>\n' + '                                <th>比例</th>');
                            });
                            this.tableData.map((item, index) => {
                                let h;
                                h = `<tr>\n' +
                                    '                                <th>${
                                        item.parentCompany ? item.parentCompany : ''
                                    }</th>\n' +
                                    '                                <th>${item.company ? item.company : ''}</th>\n' +
                                    '                                <th>${
                                        item.department ? item.department : ''
                                    }</th>`;
                                item.provinceTops.map((p, i) => {
                                    h += `<th>${p.provinceTop_Number}</th>\n" +
                                            "                                <th>${p.provinceTop_Prop}</th>`;
                                });
                                h += '</tr>';

                                $('.tbody').append(h);
                            });
                        }, 100);
                    }
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                });
        },
        companyTreeInit() {
            this.loading = true;
            this._companyConfigServiceProxy
                .getPaged(undefined, '', 100, 0)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.companyList = res.items;
                    this.companyList.forEach((item) => {
                        if (item.parentId == 0) {
                            var obj = {
                                key: item.id,
                                value: item.id,
                                title: item.fullName,
                            };
                            this.companyTreeList.push(obj);
                        }
                    });
                    this.initCompanyTree(this.companyTreeList);
                });
        },
        /**
         * 初始化树形结构数据格式
         */
        initCompanyTree(list) {
            list.forEach((v) => {
                //遍历父级数据，加载子集=
                v.children = []; //创建空的子集
                this.companyList.forEach((m) => {
                    if (m.parentId == v.value) {
                        //根据主键查子集数据
                        var obj = {
                            key: m.id,
                            value: m.id,
                            title: m.fullName,
                        };
                        v.children.push(obj); //放进父级的集合中
                        this.initCompanyTree(v.children); //递归查询子集的集合
                    }
                });
                if (v.children.length == 0) {
                    //校验如何数据没有子集，删除子集参数
                    delete v.children;
                }
            });
        },
        /**
         * 分页事件
         */
        showTotalFun() {
            return this.l(
                'GridFooterDisplayText',
                this.pageNumber,
                this.totalPages,
                this.totalItems,
                this.pagerange[0],
                this.pagerange[1]
            );
        },
        /**
         * 分页
         */
        onChangePage(page, pageSize) {
            this.pageNumber = page;
            this.request.skipCount = (page - 1) * this.request.maxResultCount;
            this.getData();
        },
        showSizeChange(current, size) {
            // this.pageNumber = 1;
            this.request.maxResultCount = size;
            this.refresh();
        },
        Export() {
            if (this.tableData.length <= 0) {
                return abp.message(this.l('NoData'));
            }
            this.loading = true;
            this._customizeReportServiceServiceProxy
                .getEmpProvinceExport(
                    this.companyId,
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this._fileDownloadService.downloadTempFile(res);
                });
        },
    },
};
</script>

<style scoped>
@import './emp-province-report.less';
</style>
